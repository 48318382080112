import React from "react"
import Layout from "../../../components/sermonLayoutSurprise"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/BY2rVc0JyCA">
    <SEO title="Six Truths About Suffering - Touching Holiness" />
  </Layout>
)

export default SermonPost
